import React from "react";
import "twin.macro";
import { Link } from "gatsby";
import { PageLayout } from "../components";

// markup
const NotFoundPage: React.FC = () => {
  return (
    <PageLayout title="Page Not Found">
      <section>
        <div tw="container mx-auto">
          <div tw="px-8 py-16">
            <h2 tw="font-semibold text-center text-denim-800 text-xl tracking-wider uppercase">
              Page Not Found
            </h2>
            <p tw="font-medium text-lg text-mkoGray-500">
              Sorry, this page doesn’t exist.
            </p>
            <p tw="font-medium text-lg text-mkoGray-500">
              Use the navigation links to find what you were looking for, or go
              back{" "}
              <Link tw="text-denim-800 hover:text-denim-500" to="/">
                Home.
              </Link>
            </p>
          </div>
        </div>
      </section>
    </PageLayout>
  );
};

export default NotFoundPage;
